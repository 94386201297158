<!--
   Created by Terence on 2022/9/13 - 11:05
-->
<template>
  <div @click="handleClick">
    <img
      :class="`banner`"
      src="https://cdn.qiandaoapp.com/ued/b8fe320d181c98681eba048e86e583b6.png"
      alt=""
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { invokeAppByYYB } from "@/utils/mob";

const props = defineProps({
  onClickBanner: {
    type: Function,
    default: () => null,
  },
});

const emits = defineEmits(["onInvokeApp"]);

const handleClick = () => {
  emits("onInvokeApp");
};
</script>

<style lang="scss" scoped>
.banner {
  display: block;
  width: 100%;
  height: auto;
}
</style>

/**
 * Created by Terence on 2022/9/9 - 17:14
 * Description :
 */
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import weekday from "dayjs/plugin/weekday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/zh-cn";

dayjs.extend(weekday);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.locale("zh-cn");

// 这种格式 06/15 周三 20:30
export const formatTimestamp = (timestamp: number) => {
  return dayjs(timestamp).format("MM/DD ddd HH:mm");
};

export const getCountdownTime = function (endTime: string) {
  const currentTime: any = dayjs();
  // const diffTime = dayjs.duration(1663665049000 - currentTime);
  const diffTime = dayjs.duration(+endTime - currentTime);
  const day = diffTime.days(); //天
  const hours = diffTime.hours(); //小时
  const minutes = diffTime.minutes(); //分钟
  const seconds = diffTime.seconds(); //秒
  const dayStr = day ? `${day}天` : "";
  const hoursStr = hours ? `${hours}小时` : "";
  const minutesStr = minutes ? `${minutes}分` : "";

  if (day) {
    return `${dayjs(+endTime).format("YYYY-MM-DD HH:mm")} `;
  } else {
    return `${dayStr}${hoursStr}${minutesStr}${seconds}秒 后`;
  }
};

export const timeDiff = (inputTime: string) => {
  const time = dayjs(inputTime);
  const now = dayjs();
  const diff = now.diff(time);

  if (diff < 60 * 1000) {
    // 秒
    return Math.floor(diff / 1000) + "秒前";
  } else if (diff < 60 * 60 * 1000) {
    // 分钟
    return Math.floor(diff / (60 * 1000)) + "分钟前";
  } else if (diff < 60 * 60 * 24 * 1000) {
    // 小时
    return Math.floor(diff / (60 * 60 * 1000)) + "小时前";
  } else if (now.year() === time.year()) {
    // 同一自然年
    return time.format("MM-DD");
  } else {
    // 非同一自然年
    return time.format("YYYY-MM-DD");
  }
};

export const getWeekDate = (time = new Date()) => {
  const weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];

  const date = new Date(time);
  const day = date.getDay();
  return weeks[day];
};
